exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-horror-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/horror/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-horror-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-intro-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/intro.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-intro-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-resume-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/resume/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-resume-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-universe-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/universe/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-src-posts-universe-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */)
}

